import { IRootData } from '@/modules/providerService/RootProvider'
import { IUser } from '@/interfaces/IUser'
import setUser from './setUser'

interface ITokenRes {
  data: {
    data: {
      tokens: {
        token: string
        tokenType: string
        exp: number
        refreshToken: string
      }[]
    }
  }
}

async function handleLogin(apiRes: ITokenRes, rootData: IRootData, setRootData: ({}) => void): Promise<{ user: IUser | null }>{
  const { user } = await setUser(
    rootData,
    setRootData
  )

  return { user }
}

export default handleLogin
