import { IRootData } from '@/modules/providerService/RootProvider'
import { PATHWAY } from '@/modules/httpService'

async function handleLogout(rootData: IRootData, setRootData: ({}) => void): Promise<void> {
  // tell the API to delete the refresh token
  try {
    await PATHWAY.delete('/tokens', {
      data: {}
    })
  } catch (err: unknown) {
    // fallback to deleting the cookie on server side
    // we will use our nextjs api to delete the cookies
    await fetch('/api/logout')
  }

  // remove user from context
  setRootData({ ...rootData, user: null })

  // redirect to home
  window.location.href = '/'
}

export default handleLogout
