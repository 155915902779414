import { PATHWAY } from '@/modules/httpService'
import { IRootData } from '@/modules/providerService/RootProvider'
import { IUser } from '@/interfaces/IUser'

async function setUser (rootData: IRootData, setRootData: ({ }) => void): Promise <{ user: IUser } | { user: null }> {
  try {
    // query API for user data
    const userRes = await PATHWAY.get('/self')

    const user = userRes.data.data.users[0]

    setRootData({ ...rootData, user })

    return { user }
  } catch (err) {
    return { user: null }
  }
}

export default setUser
