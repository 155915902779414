import decode from './decode'
import handleLogin from './handleLogin'
import setUser from './setUser'
import handleLogout from './handleLogout'

export { decode }
export { handleLogin }
export { setUser }
export { handleLogout }

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  decode,
  handleLogin,
  setUser,
  handleLogout
}
