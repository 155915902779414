import axios from 'axios'
import cookie from 'cookie'

export const PATHWAY = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_PATHWAY_API}/v1`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Function to get a specific cookie value by name
function getCookie(name: string): string | null {
  try {
    if (typeof window !== 'undefined') {
      // Client-side
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2) return parts.pop()?.split(';').shift() || null
    } else if (typeof process !== 'undefined' && process.env.NEXT_PUBLIC_PATHWAY_API) {
      // Server-side
      const cookies = cookie.parse(process.env.NEXT_PUBLIC_PATHWAY_API)
      return cookies[name] || null
    }
    return null
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.error(err.message)
      console.error(err.stack)
    }
    return null
  }
}

PATHWAY.interceptors.request.use(config => {
  const cookieValue = getCookie('NEXT_LOCALE')
  if (cookieValue) {
    config.headers['X-NEXT-LOCALE'] = cookieValue
  }

  return config
})
